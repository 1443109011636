<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'Branches',
              }"
              >分店管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>店內帳號</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
          店內帳號
        </h4>
        <div
          class="
            col-12 col-xl-6
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="
              $permissions.has($permissions.consts.BRANCH_DETAIL_MODIFY_STAFF)
            "
            v-b-modal.modal-upload
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            ><i class="fa fa-plus"></i>批次新增帳號
          </b-button>
          <b-button
            v-if="
              $permissions.has($permissions.consts.BRANCH_DETAIL_MODIFY_STAFF)
            "
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{
              name: 'BranchStaffCreate',
              params: {
                branchID: branchID,
              },
            }"
            ><i class="fa fa-plus"></i>新增帳號
          </b-button>
        </div>
        <b-breadcrumb class="col-12 d-flex items-center" :items="breadcrumb"></b-breadcrumb>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="staffs"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(actions)="data">
                  <b-dropdown id="ddown23" variant="secondary">
                    <template slot="button-content">
                      <i class="mdi mdi-menu"></i>
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'BranchStaffView',
                        params: {
                          staff_id: data.item.id,
                          branchID: branchID,
                        },
                      }"
                    >
                      查看
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        $permissions.has(
                          $permissions.consts.BRANCH_DETAIL_MODIFY_STAFF
                        )
                      "
                      :to="{
                        name: 'BranchStaffForm',
                        params: {
                          staff_id: data.item.id,
                          branchID: branchID,
                        },
                      }"
                    >
                      編輯
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        $permissions.has(
                          $permissions.consts.STAFF_BRANCH_MODIFY
                        )
                      "
                      :to="{
                        name: 'BranchStaffSetBranch',
                        params: {
                          staff_id: data.item.id,
                          branchID: branchID,
                        },
                      }"
                    >
                      設定所屬分店
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        $permissions.has(
                          $permissions.consts.STAFF_CUSTOMER_VIEW
                        )
                      "
                      :to="{
                        name: 'BranchStaffCustomerList',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      所屬會員
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        $permissions.has(
                          $permissions.consts.STAFF_ROLE_MODIFY
                        )
                      "
                      :to="{
                        name: 'BranchStaffSetRole',
                        params: { staff_id: data.item.id },
                      }"
                    >
                      設定角色
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        $permissions.has(
                          $permissions.consts.BRANCH_DETAIL_CHANGE_PASSWORD
                        )
                      "
                      :to="{
                        name: 'BranchStaffChangePassword',
                        params: {
                          staff_id: data.item.id,
                          branchID: branchID,
                        },
                      }"
                    >
                      變更密碼
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="
                        $permissions.has(
                          $permissions.consts.BRANCH_DETAIL_DELETE_STAFF
                        )
                      "
                      @click="deleteStaff(data.item)"
                    >
                      刪除
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="10"
            align="center"
            @change="getStaffs"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>

    <b-modal id="modal-upload" title="批次上傳">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳帳號 Excel 檔案</label>
          <b-form-file plain @input="uploadStaff"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="
            getSampleExcelFile(
              'staff_import_xls',
              'excel/staff_list.xlsx'
            )
          "
          ><i class="fa fa-file-text"></i>下載範例檔案</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import staffApi from "../../../apis/staff";
import branchApi from "../../../apis/branch";
import { getSampleExcelFile } from "@/utils/dashboardUtils";

export default {
  data() {
    return {
      currentPage: 1, // TODO remove
      totalRows: 1,
      showLoading: true,
      showLoadingUpload: false,
      staffs: [],
      activeBranch: null,
      sortBy: "pivot.order",
      sortDesc: false,
      fields: [
        {
          key: "pivot.order",
          label: "順序",
          sortable: true,
        },
        {
          key: "employee_code",
          label: "員工編號",
          sortable: true,
        },
        {
          key: "name",
          label: "姓名",
        },
        {
          key: "mobile_phone",
          label: "手機",
        },
        {
          key: "email",
          label: "E-Mail",
        },
        {
          key: "display_status",
          label: "狀態",
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    branchID() {
      return this.$route.params.branchID;
    },
    breadcrumb() {
      const root = {
        text: "最上層",
        to: {
          name: "Branches",
        },
      };

      const parents =
        this.activeBranch?.parents.map((parent) => {
          return {
            text: parent.parent_name,
            to: {
              name: "BranchesSubLayer",
              params: { branchID: parent.parent_id },
            },
          };
        }) ?? [];

      return [
        root,
        ...parents,
        {
          text: this.activeBranch?.name,
          active: true,
        },
      ]
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    getSampleExcelFile,
    async init() {
      await this.getStaffs();
    },
    async getStaffs() {
      this.showLoading = true;
      try {
        const { data } = await branchApi.getBranch(this.branchID);

        this.staffs = [];
        this.activeBranch = data.data;
        const now = new Date();
        data.data.staffs.forEach((staff) => {
          if (!staff.is_sys_admin) {
            this.staffs.push(staff);
          }
          staff.display_status = staff.is_enabled ? "啟用" : "未啟用";
          if (staff.left_at) {
            const leftAt = new Date(staff.left_at);
            staff.display_status =
              leftAt.getTime() > now.getTime() ? staff.display_status : "離職";
          }
        });
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得帳號資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteStaff(staff) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>員工編號：${staff.employee_code}</div>
                <div>姓名：${staff.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await staffApi.removeStaff(this.organization, staff);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + staff.name,
              });
              this.getStaffs();
            }
          }
        });
    },
    async uploadStaff(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        let response = await branchApi.importStaff(this.branchID, formData);

        if (response.status === 200 && response.data.status === "success") {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
          });

          this.getStaffs();
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message[0]) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message[0] ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
